import { HostContext, HostContextValue, useLazyUpdateState } from '@buzzeasy/shared-frontend-utilities';
import { AgentHubEventsContext, RawAgentState } from '@buzzeasy/shared-frontend-utilities/agentHubEvents';
import { App } from 'antd';
import { PropsWithChildren, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import environmentConfig from '../../environmentConfig';
import useTimeFormatters from '../../hooks/useTimeFormatters';
import { selectAuthInfo } from '../../redux/authSlice';
import { NotificationContext } from './NotificationProvider';
import { SettingsContext } from './SettingsProvider';
import { AntDesignConfigContext } from './theme/AntDesignThemeProvider';

const { apiBaseUrl } = environmentConfig;

/**
 * Provides the value for AgentUIContext.
 *
 * **Provider dependencies:**
 * - (AntD) App
 * - (Store) Provider
 * - AgentHubEventsProviderWrapper
 * - NotificationProvider
 * - SettingsProvider
 * - ThemeProviders
 * - TranslationProvider
 */
export default function HostContextProvider({ children }: PropsWithChildren): ReactElement {
  const { token } = useSelector(selectAuthInfo);
  const theme = useContext(AntDesignConfigContext);
  const [{ language }] = useContext(SettingsContext);
  const { message: messageApi } = App.useApp();
  const notificationApi = useContext(NotificationContext);
  const { subscribe, unsubscribe } = useContext(AgentHubEventsContext);

  const formattingApi = useTimeFormatters();

  const [displaySettings, setDisplaySettings] = useLazyUpdateState<RawAgentState['agentProperties']['displaySettings']>({ hideExternalContacts: false, hideSensitiveAgentInformation: false });
  const [isWindowFocused, setIsWindowFocused] = useState(document.hasFocus());

  useEffect(
    () => {
      const subId = subscribe({
        GetState: (state: RawAgentState) => setDisplaySettings(state.agentProperties.displaySettings),
      });

      return () => { unsubscribe(subId); };
    },
    [setDisplaySettings, subscribe, unsubscribe],
  );

  useEffect(
    () => {
      const focusHandler = () => setIsWindowFocused(true);
      const blurHandler = () => setIsWindowFocused(false);

      window.addEventListener('focus', focusHandler);
      window.addEventListener('blur', blurHandler);

      return () => {
        window.removeEventListener('focus', focusHandler);
        window.removeEventListener('blur', blurHandler);
      };
    },
    [],
  );

  const value = useMemo<HostContextValue>(
    () => ({
      apiBaseUrl,
      token,
      theme,
      language,
      isWindowFocused,
      displaySettings,
      messageApi,
      notificationApi,
      formattingApi,
    }),
    [displaySettings, formattingApi, isWindowFocused, language, messageApi, notificationApi, theme, token],
  );

  return (
    <HostContext.Provider value={value}>
      {children}
    </HostContext.Provider>
  );
}