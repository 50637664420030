import { ChannelType, Conversation, WorkItem } from '@buzzeasy/shared-frontend-utilities';
import { RawAgentState } from '@buzzeasy/shared-frontend-utilities/agentHubEvents';
import { AgentState } from '../../models/agent';

export function mapToInternalWorkItem(external: RawAgentState['workItems']): AgentState['workItems'] {
  return Object.values(external).map<WorkItem>((wi) => ({
    workItemId: wi.workItemId,
    workItemState: wi.workItemState.toLowerCase() as WorkItem['workItemState'],
    lastStateChangeDate: new Date(wi.lastStateChangeDate),
    createdAt: new Date(wi.createdAt),
    businessUnitId: wi.businessUnitId,
    isOutbound: wi.isOutbound,
    isPredictive: wi.isPredictive,
    primaryMediaType: wi.primaryMediaType,
    primaryChannelType: wi.primaryChannelType?.toLowerCase() as WorkItem['primaryChannelType'],
    channelId: wi.channelId || null,
    channelIdentifier: wi.channelIdentifier,
    primaryConversationId: wi.primaryConversationId,
    conversations: wi.conversations
      ? Object.values(wi.conversations).map<Conversation>(c => ({
        conversationId: c.conversationId,
        channelType: c.channelType as ChannelType,
        queueInfo: c.queuedAt
          ? { name: c.queueName ?? '', queuedAt: new Date(c.queuedAt), dequeuedAt: c.dequeuedAt ? new Date(c.dequeuedAt) : null }
          : null,
        workflowProperties: c.workflowProperties,
      }))
      : [],
    customer: {
      id: wi.customerId,
      identifier: wi.customerIdentifier,
    },
    scripting: {
      scriptProcessId: wi.scriptProcessId,
      isScriptProcessCompleted: wi.isScriptProcessCompleted,
      externalWebPageUrlTemplate: wi.externalWebPageUrlTemplate,
      externalWebPageTitle: wi.externalWebPageTitle,
    },
    caseId: wi.caseId,
    wrapUp: {
      durationSeconds: wi.wrapupDurationSeconds,
      remainingResets: wi.remainingWrapUpResets,
      forceCompletionCodeSelection: wi.forceCompletionCodeSelection,
      completionCodes: wi.usableClosureCodes as WorkItem['wrapUp']['completionCodes'],
    },
    agentWorkspace: wi.agentWorkspaceConfig,
  }));
}

export function mapToInternalAgentState(external: RawAgentState): AgentState {
  return {
    stateSince: new Date(external.agentProperties.stateSince),
    status: external.agentProperties.state,
    breakName: external.agentProperties.breakName,
    timedBreakExpiresAt: external.agentProperties.timedBreakExpiresAt ? new Date(external.agentProperties.timedBreakExpiresAt) : null,
    allowAgentsToSelectBusinessUnits: external.agentProperties.allowAgentsToSelectBusinessUnits,
    workItems: external.workItems ? mapToInternalWorkItem(external.workItems) : [],
  };
}