import { SpaceSize } from 'antd/es/space';
import React, { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { spaceSizeToNumber } from '../../utils/antDesignUtils';
import { getClassNameForComponent } from '../../utils/stringUtils';

type Alignment = 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'baseline' | 'stretch';

export interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  inline?: boolean;
  vertical?: boolean;
  wrap?: boolean;
  horizontalAlign?: Alignment;
  verticalAlign?: Alignment;
  gap?: NonNullable<SpaceSize> | [NonNullable<SpaceSize>, NonNullable<SpaceSize>];
  itemProps?: {
    grow?: number;
    shrink?: number;
  };
}

const propNames = ['inline', 'vertical', 'wrap', 'horizontalAlign', 'verticalAlign', 'gap', 'itemProps', 'grow', 'shrink'];

function shouldForwardProp(prop: string): boolean {
  return !propNames.includes(prop);
}

const Container = styled.div.withConfig({ shouldForwardProp }) <StackProps>`
  display: ${p => p.inline ? 'inline-flex' : 'flex'};
  flex-direction: ${p => p.vertical ? 'column' : 'row'};
  flex-wrap: ${p => p.wrap ? 'wrap' : undefined};

  justify-content: ${p => p.vertical ? p.verticalAlign : p.horizontalAlign};
  align-items: ${p => p.vertical ? p.horizontalAlign : p.verticalAlign};
  gap: ${p => p.gap
    ? Array.isArray(p.gap)
      ? `${spaceSizeToNumber(p.theme, p.gap[1])}px ${spaceSizeToNumber(p.theme, p.gap[0])}px`
      : `${spaceSizeToNumber(p.theme, p.gap)}px`
    : 0};

  > * {
    flex: ${p => p.itemProps?.grow ?? 0} ${p => p.itemProps?.shrink ?? 0} auto;
    max-width: 100%;
    max-height: 100%;
  }

  max-width: 100%;
  max-height: 100%;
`;

export default function Stack(props: PropsWithChildren<StackProps>): ReactElement {

  return <Container {...props} className={getClassNameForComponent({ Stack }, props.className)}>{props.children}</Container>;
}

interface StackSectionProps extends HTMLAttributes<HTMLDivElement> {
  grow?: number | boolean;
  shrink?: number | boolean;
}

const StackSectionContainer = styled.div.withConfig({ shouldForwardProp }) <StackSectionProps>`
  display: inherit;
  flex-direction: inherit;
  flex-wrap: inherit;

  justify-content: inherit;
  align-items: inherit;
  gap: inherit;

  flex-grow: ${p => (typeof p.grow === 'boolean' && p.grow) ? 1 : p.grow};
  flex-shrink: ${p => (typeof p.shrink === 'boolean' && p.shrink) ? 1 : p.shrink};
  min-width: 0;
  min-height: 0;
`;

Stack.Section = function StackSection(props: StackSectionProps): ReactElement {

  return <StackSectionContainer {...props} className={getClassNameForComponent({ StackSection }, props.className)}>{props.children}</StackSectionContainer>;
};