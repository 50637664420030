import { HostContext } from '@buzzeasy/shared-frontend-utilities';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MINUTE } from '../../hooks/useTimeFormatters.helpers';

interface RonaNotificationHandler {
  onRonaStart(): void;
  onRonaEnd(): void;
}

export function useRonaNotificationHandler(): RonaNotificationHandler {
  const { notificationApi } = useContext(HostContext);
  const { i18n, t } = useTranslation();

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const onRonaStart = useCallback(
    () => {
      notificationApi.warning({ message: t('notification.rona.title'), description: <Trans i18n={i18n} i18nKey="notification.rona.content" />, key: 'aui-stateChange', role: 'status' });

      intervalRef.current = setInterval(
        () => notificationApi.warning({ key: 'aui-ronaReminder', message: t('notification.ronaReminder.title'), description: <Trans i18n={i18n} i18nKey="notification.ronaReminder.content" />, role: 'status' }),
        2 * MINUTE * 1000,
      );
    },
    [i18n, notificationApi, t],
  );

  const onRonaEnd = useCallback(
    () => {
      if (intervalRef.current)
        clearInterval(intervalRef.current);

      notificationApi.destroy('aui-stateChange');
      notificationApi.destroy('aui-ronaReminder');
    },
    [notificationApi],
  );

  return useMemo(
    () => ({ onRonaStart, onRonaEnd }),
    [onRonaEnd, onRonaStart],
  );
}