import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../components/providers/SettingsProvider';
import { formatDateDefault, formatDateEU, formatDateISO, formatDateUS, formatDateWritten, formatTime12Hour, formatTime24Hour, formatTimeDefault, formatTimeSpanNormal, formatTimeSpanWritten } from './useTimeFormatters.helpers';

function toDate(date: string | Date): Date {
  return typeof date === 'string' ? new Date(date) : date;
}

interface HookReturnType {
  formatDate(timestamp: string | Date): string;
  formatTime(timestamp: string | Date): string;
  formatDateTime(timestamp: string | Date): string;
  formatTimeSpan(seconds: number): string;
}

function useTimeFormatters(): HookReturnType {
  const [{ dateFormat, timeFormat, timeSpanFormat }] = useContext(SettingsContext);
  const { t } = useTranslation();

  const formatDate = useCallback<HookReturnType['formatDate']>(
    (date) => {
      const actualDate = toDate(date);

      switch (dateFormat) {
        case null:
          return formatDateDefault(actualDate);
        case 'iso':
          return formatDateISO(actualDate);
        case 'eu':
          return formatDateEU(actualDate);
        case 'us':
          return formatDateUS(actualDate);
        case 'written':
          return formatDateWritten(actualDate);
        default:
          throw new Error(`Invalid date format '${dateFormat}'`);
      }
    },
    [dateFormat],
  );

  const formatTime = useCallback<HookReturnType['formatTime']>(
    (date) => {
      const actualDate = toDate(date);

      switch (timeFormat) {
        case null:
          return formatTimeDefault(actualDate);
        case '24hour':
          return formatTime24Hour(actualDate);
        case '24hour+sec':
          return formatTime24Hour(actualDate, true);
        case '12hour':
          return formatTime12Hour(t, actualDate);
        case '12hour+sec':
          return formatTime12Hour(t, actualDate, true);
        default:
          throw new Error(`Invalid time format '${timeFormat}'`);
      }
    },
    [t, timeFormat],
  );

  const formatDateTime = useCallback<HookReturnType['formatDateTime']>(
    (date) => `${formatDate(date)} ${formatTime(date)}`,
    [formatDate, formatTime],
  );

  const formatTimeSpan = useCallback<HookReturnType['formatTimeSpan']>(
    (seconds) => {
      switch (timeSpanFormat) {
        case 'normal':
          return formatTimeSpanNormal(t, seconds);
        case 'written':
          return formatTimeSpanWritten(t, seconds);
        default:
           
          throw new Error(`Invalid time span format '${timeSpanFormat}'`);
      }
    },
    [t, timeSpanFormat],
  );

  const value = useMemo(
    () => ({
      formatDate,
      formatTime,
      formatDateTime,
      formatTimeSpan,
    }),
    [formatDate, formatDateTime, formatTime, formatTimeSpan],
  );

  return value;
}

export default useTimeFormatters;