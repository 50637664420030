import { PropsWithChildren, ReactElement } from 'react';
import { Provider } from 'react-redux';
import ActivityInfoProvider from '../../components/providers/ActivityInfoProvider';
import AgentHubEventsProviderWrapper from '../../components/providers/AgentHubEventsProviderWrapper';
import AgentStateProvider from '../../components/providers/AgentStateProvider';
import AntDesignAppProvider from '../../components/providers/AntDesignAppProvider';
import HostContextProvider from '../../components/providers/HostContextProvider';
import NotificationProvider from '../../components/providers/NotificationProvider';
import SettingsProvider from '../../components/providers/SettingsProvider';
import TranslationProvider from '../../components/providers/TranslationProvider';
import WorkItemInfoProvider from '../../components/providers/WorkItemInfoProvider';
import AuthProvider from '../../components/providers/auth/AuthProvider';
import ThemeProviders from '../../components/providers/theme/ThemeProviders';
import { store } from '../../redux/store';
import SessionRecordingProvider from './SessionRecordingProvider';
import WebRtcSessionProviderWrapper from './WebRtcSessionProviderWrapper';

/**
 * Shared providers between the Main and CRM apps.
 */
export default function BaseProviders({ children }: PropsWithChildren): ReactElement {

  return (
    <Provider store={store}>
      <TranslationProvider>
        <SettingsProvider>
          <ThemeProviders>
            <AntDesignAppProvider>
              <NotificationProvider>
                <AuthProvider>
                  <SessionRecordingProvider>
                    <AgentHubEventsProviderWrapper>
                      <HostContextProvider>
                        <AgentStateProvider>
                          <WorkItemInfoProvider>
                            <ActivityInfoProvider>
                              <WebRtcSessionProviderWrapper>
                                {children}
                              </WebRtcSessionProviderWrapper>
                            </ActivityInfoProvider>
                          </WorkItemInfoProvider>
                        </AgentStateProvider>
                      </HostContextProvider>
                    </AgentHubEventsProviderWrapper>
                  </SessionRecordingProvider>
                </AuthProvider>
              </NotificationProvider>
            </AntDesignAppProvider>
          </ThemeProviders>
        </SettingsProvider>
      </TranslationProvider>
    </Provider>
  );
}