import { ReactElement } from 'react';
import styled from 'styled-components';

export interface RecordingIconProps {
  active?: boolean;
}

export default function RecordingIcon({ active = true }: RecordingIconProps): ReactElement {
  
  return (
    <RecordingIcon.Outline>
      {active && <RecordingIcon.Fill />}
    </RecordingIcon.Outline>
  );
}

RecordingIcon.Outline = styled.span`
  --lineWidth: max(1px, round(nearest, calc(1em / 16), 1px));

  display: inline-block;
  width: 1em;
  height: 1em;
  border: var(--lineWidth) solid ${p => p.theme.colorError};
  border-radius: 1000000px;
  padding: calc(var(--lineWidth) * 2);
`;

RecordingIcon.Fill = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1000000px;
  background-color: ${p => p.theme.colorError};
  
  animation: RecordingIcon-blink 2s linear;
  animation-iteration-count: infinite;

  @keyframes RecordingIcon-blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`;