import { WebRtcSessionProps, WebRtcSessionProvider } from '@buzzeasy/call-control-fragment-2';
import { PropsWithChildren, ReactElement, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import environmentConfig from '../../environmentConfig';
import { selectAuthInfo } from '../../redux/authSlice';
import { NotificationContext } from './NotificationProvider';
import { SettingsContext } from './SettingsProvider';

const { apiBaseUrl } = environmentConfig;

/**
 * Serves as a wrapper for the WebRtcSessionProvider.
 *
 * **Provider dependencies:**
 * - (Store) Provider
 * - NotificationProvider
 * - SettingsProvider
 */
export default function WebRtcSessionProviderWrapper({ children }: PropsWithChildren): ReactElement {
  const { token } = useSelector(selectAuthInfo);
  const notificationApi = useContext(NotificationContext);
  const [{ language, ringtoneVolume }] = useContext(SettingsContext);

  const options = useMemo<WebRtcSessionProps['options']>(
    () => ({
      ringtoneVolume,
    }),
    [ringtoneVolume],
  );

  //sip.js session manager patched autoStop property belongs to this provider. It's a temporary solution to fix dropping calls on a canceled page refresh request.
  return (
    <WebRtcSessionProvider apiBaseUrl={apiBaseUrl} token={token} notificationApi={notificationApi} language={language} options={options}>
      {children}
    </WebRtcSessionProvider>
  );
}