import { ParseKeys } from 'i18next';
import { createContext, PropsWithChildren, ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';
import ErrorFallback from './ComponentErrorBoundary.Fallback';

type SimpleValue = string | number | boolean | null;

export interface ErrorContextInfo {
  key: string;
  value: SimpleValue;
}

export interface ErrorDisplayInfo extends ErrorContextInfo {
  label: string;
}

export interface ErrorBoundaryInfoContextValue {
  info: Record<string, SimpleValue>;
  addOrUpdateInfo(info: ErrorContextInfo): void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ErrorInfoContext = createContext<ErrorBoundaryInfoContextValue>(undefined!);

export interface ComponentErrorBoundaryProps extends Pick<ErrorBoundaryProps, 'onError'> {
  componentNameTKey?: ParseKeys<'translation'>;
  displayInfo?: ErrorDisplayInfo[];
  allowPageRefresh?: boolean;
}

export default function ComponentErrorBoundary({ children, ...props }: PropsWithChildren<ComponentErrorBoundaryProps>): ReactElement {
  const parentErrorInfoContext = useContext(ErrorInfoContext) as ErrorBoundaryInfoContextValue | undefined; // top level boundary will not have a parent context

  const [contextInfo, setContextInfo] = useState<ErrorBoundaryInfoContextValue['info']>({});

  const aggregatedContextInfo = useMemo<ErrorBoundaryInfoContextValue['info']>(
    () => ({
      ...(props.displayInfo ? Object.fromEntries(props.displayInfo.map(({ key, value }) => [key, value])) : {}),
      ...contextInfo,
      ...parentErrorInfoContext?.info ?? {},
    }),
    [contextInfo, parentErrorInfoContext?.info, props.displayInfo],
  );

  const addOrUpdateInfo = useCallback<ErrorBoundaryInfoContextValue['addOrUpdateInfo']>(
    (info) => setContextInfo((prevInfo) => ({ ...prevInfo, [info.key]: info.value })),
    [],
  );

  const contextValue = useMemo<ErrorBoundaryInfoContextValue>(
    () => ({
      info: aggregatedContextInfo,
      addOrUpdateInfo,
    }),
    [addOrUpdateInfo, aggregatedContextInfo],
  );

  return (
    <ErrorBoundary fallbackRender={(fallBackProps) => <ErrorFallback {...fallBackProps} {...props} contextInfo={aggregatedContextInfo} />}>
      <ErrorInfoContext.Provider value={contextValue}>
        {children}
      </ErrorInfoContext.Provider>
    </ErrorBoundary>
  );
}
