import { WorkItem } from '@buzzeasy/shared-frontend-utilities';
import { PropsWithChildren, ReactElement, createContext, useCallback, useState } from 'react';
import { Customer } from '../../models/customer';

type WorkItemId = WorkItem['workItemId'];

export interface WorkItemInfo {
  customer?: Customer;
  crmOutboundCustomerIdentifier?: string;
}

export const defaultWorkItemInfo: WorkItemInfo = { customer: undefined, crmOutboundCustomerIdentifier: undefined };

export interface WorkItemContextValue {
  workItemInfoDict: Record<WorkItemId, WorkItemInfo | undefined>;
  setInfo(workItemId: WorkItemId, info: WorkItemInfo | ((currentInfo: WorkItemInfo | undefined) => WorkItemInfo)): void;
  removeInfo(workItemId: WorkItemId): void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const WorkItemInfoContext = createContext<WorkItemContextValue>(undefined!);

/**
 * Provides extra information for workItems.
 *
 * **No provider dependencies**
 */
export default function WorkItemInfoProvider({ children }: PropsWithChildren): ReactElement {

  const [workItemInfoDict, setWorkItemInfoDict] = useState<Record<WorkItemId, WorkItemInfo | undefined>>({});

  const setInfo = useCallback(
    (wId: WorkItemId, info: WorkItemInfo | ((currentInfo: WorkItemInfo | undefined) => WorkItemInfo)) => setWorkItemInfoDict(curr => ({
      ...curr,
      [wId]: typeof info === 'function' ? info(curr[wId]) : info,
    })),
    [],
  );

  const removeInfo = useCallback(
    (wId: WorkItemId) => setWorkItemInfoDict(curr => {
      const newDict = { ...curr };
      delete newDict[wId];
      return newDict;
    }),
    [],
  );

  return (
    <WorkItemInfoContext.Provider value={{ workItemInfoDict, setInfo, removeInfo }}>
      {children}
    </WorkItemInfoContext.Provider>
  );
}