import { getItem, setItem, usePureEffect } from '@buzzeasy/shared-frontend-utilities';
import { App, Button } from 'antd';
import { H } from 'highlight.run';
import { createContext, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import environmentConfig from '../../environmentConfig';
import { selectAuthInfo } from '../../redux/authSlice';
import ComponentErrorBoundary from '../ComponentErrorBoundary';
import Stack from '../layouts/Stack';
import RecordingIcon from './SessionRecordingProvider.RecordingIcon';

const { environment, buildNumber, licenseKeys } = environmentConfig;

const sessionRecordingTriggerKey = 'highlight_session_recording_trigger';
const recordingInProgressMessageKey = 'highlightRecordingInProgress';

const shouldStartRecording = getItem<boolean>(sessionRecordingTriggerKey, false);

export interface SessionRecordingContextValue {
  sessionRecordingInProgress: boolean;
  startSessionRecording(): void;
  stopSessionRecording(): void;
}

H.init(licenseKeys.highlight, {
  serviceName: 'AgentUI',
  environment,
  version: buildNumber,
  disableSessionRecording: !shouldStartRecording,
  manualStart: true,
  networkRecording: { enabled: true, recordHeadersAndBody: true },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SessionRecordingContext = createContext<SessionRecordingContextValue>(undefined!);

/**
 * Provides Highlight session recording start, stop and status.
 *
 * **Provider dependencies:**
 * - (Store) Provider
 * - ThemeProviders (because of the {@link ComponentErrorBoundary})
 * - TranslationProvider (because of the {@link ComponentErrorBoundary})
 */
export default function SessionRecordingProvider({ children }: PropsWithChildren): ReactElement {
  const { user, tenantId } = useSelector(selectAuthInfo);
  const { message } = App.useApp();
  const { t } = useTranslation();
  
  const [isRecording, setIsRecording] = useState(H.getRecordingState() === 'Recording');
  const [isReady, setIsReady] = useState(false);

  useEffect(
    () => {
      H.identify(user.id, {
        email: user.email ?? 'n/a',
        tenantId,
      });
    },
    [tenantId, user.email, user.id],
  );

  const startSessionRecording = useCallback(
    () => {
      setItem(sessionRecordingTriggerKey, true);
      window.location.reload();
    },
    [],
  );

  const stopSessionRecording = useCallback(
    () => {
      setItem(sessionRecordingTriggerKey, false);
      H.stop();
      setIsRecording(false);
      message.destroy(recordingInProgressMessageKey);
    },
    [message],
  );

  usePureEffect(
    (deps) => {
      H.onHighlightReady(() => setIsReady(true));
      
      if (shouldStartRecording && H.getRecordingState() === 'NotRecording') {
        H.start({ forceNew: true });
        setIsRecording(true);
        deps.message.open({
          type: 'loading',
          key: recordingInProgressMessageKey,
          duration: 0,
          icon: <></>,
          content: (
            <Stack verticalAlign="center" gap="small">
              <RecordingIcon />
              <span>{deps.t('sessionRecording.recordingIsInProgress')}</span>
              <Button type="primary" danger size="small" onClick={deps.stopSessionRecording}>
                {deps.t('sessionRecording.stop')}
              </Button>
              <Button type="default" size="small" onClick={() => deps.message.destroy(recordingInProgressMessageKey)}>
                {deps.t('sessionRecording.dismiss')}
              </Button>
            </Stack>
          ),
        });
      }
      else {
        setIsReady(true);
      }

      setItem(sessionRecordingTriggerKey, false);
    },
    [],
    { message, stopSessionRecording, t },
  );

  const contextValue = useMemo(
    () => ({ sessionRecordingInProgress: isRecording, startSessionRecording, stopSessionRecording }),
    [isRecording, startSessionRecording, stopSessionRecording],
  );

  // TODO: reintroduce HighlightErrorBoundary with custom dialog instead of our ComponentErrorBoundary

  return (
    <SessionRecordingContext.Provider value={contextValue}>
      {
        isReady &&
        <ComponentErrorBoundary>
          {children}
        </ComponentErrorBoundary>
      }
    </SessionRecordingContext.Provider>
  );
}