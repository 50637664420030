import { Spin } from 'antd';
import React, { ComponentType, ReactElement, Suspense } from 'react';
import styled from 'styled-components';

const CenteredSpin = styled(Spin)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BaseSuspendedComponentProps<C extends ComponentType<any>> {
  component: React.LazyExoticComponent<C>;
  noFallback?: boolean;
}

export type SuspendedComponentProps<C> = C extends ComponentType<infer P>
  ? BaseSuspendedComponentProps<C> & P
  : BaseSuspendedComponentProps<ComponentType<Record<string, never>>>;

export default function Suspended<C>({ component: Component, noFallback, ...props }: SuspendedComponentProps<C>): ReactElement {

  return (
    <Suspense fallback={noFallback ? null : <CenteredSpin />}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Component {...props as any} />
    </Suspense>
  );
}