import { AgentStatus, useStateChangeCallbacks } from '@buzzeasy/shared-frontend-utilities';
import { AgentHubEventsContext, RawAgentState, RawBusinessUnit, RawForcedStateChangeInfo } from '@buzzeasy/shared-frontend-utilities/agentHubEvents';
import { PropsWithChildren, ReactElement, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Trans, useTranslation } from 'react-i18next';
import environmentConfig from '../../environmentConfig';
import { AgentState } from '../../models/agent';
import { Application } from '../../models/misc';
import blenderApi from '../../redux/apis/blenderApi';
import { ErrorInfoContext } from '../ComponentErrorBoundary';
import { mapToInternalAgentState } from './AgentStateProvider.mappers';
import { useRonaNotificationHandler } from './AgentStateProvider.useRonaNotificationHandler';
import { NotificationContext } from './NotificationProvider';
import { crmLoggedOutPath, loggedOutPath } from './auth/AuthProvider';

const { crmMode } = environmentConfig;

interface StatusAndBreak {
  status: AgentStatus;
  breakName: string | null;
}

interface ExtendedAgentState extends AgentState {
  applications: Application[];
  businessUnits: RawBusinessUnit[];
}

export type AgentStateContextValue = [
  /**
   * The current state of the agent.
   */
  state: ExtendedAgentState,
  /**
   * Actions to modify the agent state.
   */
  actions: {
    launchApplication(app: Application): void;
    closeApplication(appName: Application['appId']): void;
  },
];

const defaultAgentState: AgentState = {
  stateSince: new Date(),
  status: 'Break',
  breakName: null,
  timedBreakExpiresAt: null,
  allowAgentsToSelectBusinessUnits: false,
  workItems: [],
};

export const AgentStateContext = createContext<AgentStateContextValue>([
  { ...defaultAgentState, applications: [], businessUnits: [] },
  { launchApplication: () => { }, closeApplication: () => { } },
]);

/**
 * Responsible for handling the SignalR connection with the BlenderGara AgentHub by maintaining the logged in agent's status and displaying notifications regarding forced state changes.
 *
 * **Provider dependencies:**
 * - (Store) Provider
 * - AgentHubEventsProvider
 * - NotificationProvider
 * - TranslationProvider
 */
export default function AgentStateProvider({ children }: PropsWithChildren): ReactElement {
  const notificationApi = useContext(NotificationContext);
  const { subscribe, unsubscribe } = useContext(AgentHubEventsContext);
  const { addOrUpdateInfo } = useContext(ErrorInfoContext);
  const { i18n, t } = useTranslation();

  const [agentState, setAgentState] = useState<AgentState>(defaultAgentState);
  const [launchedApplications, setLaunchedApplications] = useState<Application[]>([]);
  const [businessUnits, setBusinessUnits] = useState<RawBusinessUnit[]>([]);

  const [fetchBusinessUnits] = blenderApi.useLazyGetBusinessUnitsQuery();

  const { onRonaStart, onRonaEnd } = useRonaNotificationHandler();

  useStateChangeCallbacks(agentState, [
    [gotoLoggedOutPage, { trigger: ({ status }) => status === 'LoggedOut' }],
    [onRonaStart, { trigger: ({ status, breakName }) => isStatusRONA({ status, breakName }) }],
    [onRonaEnd, { trigger: ({ status, breakName }) => !isStatusRONA({ status, breakName }) }],
  ]);

  const updateAgentState = useCallback(
    (externalState: RawAgentState) => {
      const newState = mapToInternalAgentState(externalState);
      addOrUpdateInfo({ key: 'Agent State', value: JSON.stringify(newState) });
      setAgentState(curr => graftNewStateToOld(curr, newState));
    },
    [addOrUpdateInfo],
  );

  const handleStateTransition = useCallback(
    ({ toState }: RawForcedStateChangeInfo) => {
      if (toState === 'LoggedOut') {
        gotoLoggedOutPage();
      }
      else {
        notificationApi.info({ message: t('notification.forcedStateXChange.title'), description: <Trans i18n={i18n} i18nKey="notification.forcedStateXChange.content" values={{ value: t('agentStatuses.', { context: toState }).capitalize() }} />, key: 'aui-forcedStateChange', role: 'status' });
      }
    },
    [i18n, notificationApi, t],
  );

  useEffect(
    () => {
      // fetch business units on mount
      fetchBusinessUnits().unwrap()
        .then(setBusinessUnits);
    },
    [fetchBusinessUnits],
  );

  useEffect(
    () => {
      const subId = subscribe({
        GetState: updateAgentState,
        StateTransitionRequested: handleStateTransition,
        OnBusinessUnitsChanged: setBusinessUnits,
      });

      return () => { unsubscribe(subId); };
    },
    [handleStateTransition, i18n, notificationApi, subscribe, t, unsubscribe, updateAgentState],
  );

  const launchApplication = useCallback(
    (app: Application) => setLaunchedApplications(curr => [...curr, app]),
    [],
  );

  const closeApplication = useCallback(
    (appName: Application['appId']) => setLaunchedApplications(curr => curr.filter(app => app.appId !== appName)),
    [],
  );

  const contextValue = useMemo<AgentStateContextValue>(
    () => [
      { ...agentState, applications: launchedApplications, businessUnits },
      { launchApplication, closeApplication },
    ],
    [agentState, businessUnits, closeApplication, launchApplication, launchedApplications],
  );

  return (
    <AgentStateContext.Provider value={contextValue}>
      {children}
    </AgentStateContext.Provider>
  );
}

/**
 * Merges the new state with the old state, keeping the old state's work items if they haven't changed.
 */
function graftNewStateToOld(oldState: AgentState, newState: AgentState): AgentState {
  return {
    ...newState,
    workItems: newState.workItems.map(newWI => {
      const oldWI = oldState.workItems.find(oWi => oWi.workItemId === newWI.workItemId);

      if (!oldWI)
        return newWI;

      if (isEqual(oldWI, newWI))
        return oldWI;

      if (isEqual(oldWI.conversations, newWI.conversations))
        return { ...newWI, conversations: oldWI.conversations };

      return newWI;
    }),
  };
}

function isStatusRONA({ status, breakName }: StatusAndBreak): boolean {
  return status === 'Break' && breakName === 'RONA';
}

function gotoLoggedOutPage() {
  window.location.replace(crmMode ? crmLoggedOutPath : loggedOutPath);
}
